import { useListContext } from "react-admin";

import { Button, Toolbar } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

const Pagination = (): JSX.Element => {
  const { page, perPage, total, setPage } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  return (
    <Toolbar>
      <Button
        color="primary"
        key="prev"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        <ChevronLeft />
        Prev
      </Button>

      <span>
        {page} / {nbPages}
      </span>

      <Button
        color="primary"
        key="next"
        onClick={() => setPage(page + 1)}
        disabled={page === nbPages}
      >
        Next
        <ChevronRight />
      </Button>
    </Toolbar>
  );
};

export default Pagination;
