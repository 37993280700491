import { FC } from "react";
import { useRecordContext, PublicFieldProps } from "react-admin";

const { REACT_APP_STAGE } = process.env;

const InstanceLinkField: FC<PublicFieldProps> = ({
  source,
}: PublicFieldProps) => {
  const record = useRecordContext();
  const idSource: string = record && source && record[source];

  return (
    <span>
      <a
        href={`https://${idSource}.ci.${REACT_APP_STAGE}.platform.landingai.io`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {idSource}
      </a>
    </span>
  );
};

export default InstanceLinkField;
