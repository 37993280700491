import { List, Datagrid, TextField, DateField, ListProps } from "react-admin";
import Pagination from "../components/Pagination";
import DurationField from "../components/DurationField";
import DeleteButton from "../components/DeleteButton";
import InstanceLinkField from "../components/InstanceLinkField";
import ResizeButton from "../components/ResizeButton";

const InferenceInstances = (props: ListProps): JSX.Element => (
  <List
    {...props}
    pagination={<Pagination />}
    bulkActionButtons={false}
    title="Landing AI - Cluster Infernce Provsioning"
  >
    <Datagrid>
      <InstanceLinkField source="inference_instance_id" sortable={false} />
      <TextField source="device_id" sortable={false} />
      <TextField source="status" sortable={false} />
      <TextField source="user_email" sortable={false} />
      <TextField source="org_id" sortable={false} />
      <TextField source="cluster_size" sortable={false} />
      <DateField source="created_at" sortable={false} />
      <DurationField source="total_uptime" sortable={false} />
      <DeleteButton />
      <ResizeButton />
    </Datagrid>
  </List>
);

export default InferenceInstances;
