import { fetchUtils } from "react-admin";
import { DataProvider, GetListParams } from "ra-core";
import buildUrl from "build-url";

const { REACT_APP_API_HOST } = process.env;
const httpClient = fetchUtils.fetchJson;

const deleteEndpoint = `${REACT_APP_API_HOST}/clp/v1/apps`;
const resizeEndpoint = (inference_instance_id: string) =>
  `${REACT_APP_API_HOST}/cip/api/v1/inference-instance/${inference_instance_id}/resize`;

const deleteInstance = async (device_id: string): Promise<void> => {
  const url = buildUrl(deleteEndpoint, {
    queryParams: {
      id: device_id,
    },
  });

  try {
    await httpClient(url, { method: "DELETE", credentials: "include" });
    location.reload();
  } catch (error) {
    console.error(error);
    alert("Error deleting instance");
  }
};

const pauseResumeInstance = async (
  inference_instance_id: string,
  type: "pause" | "resume"
): Promise<void> => {
  const url = buildUrl(resizeEndpoint(inference_instance_id));

  try {
    await httpClient(url, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ type }),
    });
    location.reload();
  } catch (error) {
    console.error(error);
    alert("Error pausing/resuming instance");
  }
};

const dataProvider: DataProvider = {
  getList: async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      page: `${page}`,
      per_page: `${perPage}`,
      filter: JSON.stringify(params.filter),
    };
    const url = buildUrl(`${REACT_APP_API_HOST}/cip/api/v1/admin`, {
      path: resource,
      queryParams: query,
    });

    const { json } = await httpClient(url, { credentials: "include" });
    return {
      data: json.data.items,
      total: json.data.total,
    };
  },
} as DataProvider;

export { dataProvider, deleteInstance, pauseResumeInstance };
