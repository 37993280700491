import { FC } from "react";
import { useRecordContext, PublicFieldProps } from "react-admin";
import { duration } from "moment";

const DurationField: FC<PublicFieldProps> = ({ source }: PublicFieldProps) => {
  const record = useRecordContext();
  // Assumed to be in seconds
  const durationSource: number = record && source && record[source];

  if (durationSource === 0) {
    return <span>NA</span>;
  }

  return <span>{duration(durationSource * 1000).humanize()}</span>;
};

export default DurationField;
