import { Admin, Resource } from "react-admin";
import { dataProvider } from "./services/data-provider";
import InferenceInstances from "./views/InferenceInstances";
import TopLayout from "./components/TopLayout";

const App = (): JSX.Element => (
  <Admin layout={TopLayout} dataProvider={dataProvider}>
    <Resource name="inference-instance" list={InferenceInstances} />
  </Admin>
);

export default App;
